<template>
  <div>
    <!-- <Navigation/> -->
      <b-overlay :show="isProcessingPayment" rounded> 
        <main class="main">
          <div class="bank-response container">    

            <div class="contenido"  v-if="urlParams.nbresponse == 'Aprobado'" >                
              <b-avatar variant="success" size="70" class="shadow mb-2" >
                <feather-icon size="28" icon="CheckIcon" />
              </b-avatar>
              <h1 class="mb-1 mt-50"> {{ $t('bankResponse.congratulation') }} </h1>
              <b-card-text class="m-auto w-75">
                {{ $t('bankResponse.youPaymentWasCompleted') }} <strong> {{ $t('bankResponse.successfully') }}  </strong>.
              </b-card-text>  
            </div> 

            <div class="contenido"  v-else>
              <figure class="thankyou__picture">
                <img :src="require('@/assets/images/credit-card.png')" alt="illustration-1" class="payment__img">
              </figure>         
              <h3 class="mb-1"> {{ $t('bankResponse.weAreUnableToProcessYourPayment') }} </h3> 

              <b-card-text class="m-auto">
                {{ $t('bankResponse.wouldYouLikeToTryAgain') }}
              </b-card-text>
              <button class="btn-back mt-4"  @click="retryPayment" v-if="urlParams.order"> {{ $t('bankResponse.retryPayment')}} </button>  

              <div class="footer-bresponse mt-2"  >                 
                <p class="mb-0"><strong>{{$t('bankResponse.email') }}:</strong> {{urlParams.email}}</p>                                      
                <p class="mb-0"><strong>{{$t('bankResponse.total')}}:</strong> {{urlParams.importe}}</p>
              </div>  
            </div> 

          </div>                         
        </main>
      </b-overlay>                        
    <!-- <Footer/> -->
  </div>
</template>

<script>
import {  mapActions, mapMutations, mapState } from 'vuex'
import { toJson, removeNullValuesFromAnObject } from '@/helpers/helpers'

import Navigation from '@/modules/shop/components/experiences/NavigationLite'
import Footer from '@/modules/shop/components/experiences/FooterLite'
import { market } from '@/modules/shop/mixins/market'

    export default {
        mixins:[market],
        created(){
          document.title = `${process.env.VUE_APP_NAME } | Bank Response`  // 87660
          this.getParamsUrl()
        },       
        components: {     
          Navigation,           
          Footer,        
        }, 
        data(){
          return {
            statusOrder: null,                
            isProcessingPayment: false,
            urlParams: null
          }
        },
        computed:{
            ...mapState('auth',['queryParams']),                                
        },
        methods:{            
            ...mapActions('shop',['checkOrderStatusPayment','retryPaymentOrder']),
            ...mapMutations('shop',['setOrderCreated']),
            getParamsUrl(){
              const queryParams = toJson( removeNullValuesFromAnObject( this.$route.query )) 
              
              if(queryParams){
                queryParams.success = (queryParams.success === 'true') ? true : false
                queryParams.order = queryParams.referencia ? queryParams.referencia.split("-")[2] : null // SALES-WMH-88603-63367 el tercer valor es la order
              }
              this.urlParams = queryParams              
            },                       
            isValidIdOrder(){    
              const idOrder = this.$route.params?.order                
              const isNumber = idOrder ? this.isANumber( idOrder ) : false              
              return isNumber && idOrder > 0 // si es numero y que sea mayor a 0
            },
            async retryPayment(){
              this.isProcessingPayment = true
              const payload = { order: this.urlParams.order } 
              console.log(payload)
              console.log(window.location.origin + '/payment'  )

              this.setOrderCreated(null) //pongo nulo state de orderCreated      
              const newDataPayment = await this.retryPaymentOrder(payload)
              newDataPayment.order = newDataPayment.invoice.order                      
              this.setOrderCreated(newDataPayment) //pongo en el state lo nuevo  
             
              window.parent.location.href = window.location.origin + '/payment' // "http://192.168.42.200:8080/payment"; 
              this.isProcessingPayment = false                             
            },
        }
    }
    

  /*   {
      "status": true,
      "order": 87658,
      "invoice": "WMH-87658 ",
      "payment": {
          "error": false,
          "url": "https://payment.pay2.secured-by-ingenico.com
          /checkout/12199-d01960b2b64a43f3b09afcfdfa67072c:063e66fe-8922-71ff-9c7b-ebcf7aea2384:2c2fe4963ed749d7a497f66f47258871"
      },
      "terminal": "",
      "msj": ""
      payment/bank-response?success=false&nuAut=&nbResponse=Rechazado&referencia=SALES-WMH-88603-63367&importe=130%20USD&email=engelcituk%40gmail.com&fecha=2023-02-14
  }
  */
</script>


<style lang="scss" scoped>
  @import "@/assets/scss/bank-response.scss";
</style>